import { NavBar } from "./components/NavBar/NavBar";
import { HelloBlock } from "./components/HelloBlock/HelloBlock";
import { Chars } from "./components/Chars/Chars";
import { FillOutStyle } from "./components/FillOutStyle/FillOutStyle";
import { Gallery } from "./components/Gallery/Gallery";
import { Command } from "./components/Command/Command";
import { BuyMe } from "./components/BuyMe/BuyMe";
import { Reviews } from "./components/Reviews/Reviews";
import { Footer } from "./components/Footer/Footer";
import { Events } from "./components/Events/Events";
import { IsMobileProvider } from "./contexts/isMobileContext";
import { Repertoire } from "./components/Repertoire/Repertoire";

function App() {
  return (
    <IsMobileProvider>
      <NavBar />
      <main className="bg-black text-white z-0 absolute top-0 w-full min-h-screen flex flex-col items-center justify-between">
        <HelloBlock />
        <Chars />
        <FillOutStyle />
        <Repertoire />
        <Events />
        <Gallery />
        <Command />
        <BuyMe />
        <Reviews />
        <Footer />
      </main>
    </IsMobileProvider>
  );
}

export default App;
