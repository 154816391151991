export const BuyMe = () => {
  return (
    <div className="mt-7 w-full md:w-11/12 relative flex flex-col items-center justify-center text-center md:py-24">
      <img
        src="/img/bg.png"
        alt=""
        className="w-full md:w-11/12 absolute bottom-0 md:hidden"
      />

      <div className="w-full h-full bg-black absolute opacity-75 hidden md:block"></div>
      <div className="relative z-10 flex flex-col gap-y-3 md:gap-y-6 items-center justify-center mb-32 md:mb-0">
        <p className="text-xl md:text-3xl">
          просчитайте и получите стоимость выступления кавер-группы
        </p>
        <p className="text-2xl md:text-xl font-bold">
          без созвона с менеджером
        </p>
        <a
          rel="noopener noreferrer"
          href="https://wa.me/79891563506"
          target="_blank"
          className="text-xl md:text-3xl cursor-pointer text-mainPurple w-5/6 md:w-fit mt-2 py-4 md:px-10 border border-mainPurple rounded-2xl"
        >
          Узнать стоимость
        </a>
      </div>
    </div>
  );
};
