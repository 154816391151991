import "animate.css/animate.min.css";
import { ParicleBG, ParticleImage } from "../ParticleBG/ParticleBG";

export const HelloBlock = () => {
  const HelloBlockParticleBg = {
    width: 200,
    height: 200,
    src: "/img/helloBlock/notes.png",
  } as ParticleImage;

  return (
    <div className="relative h-screen w-full overflow-hidden flex items-center justify-center">
      <div className="w-full h-screen absolute overflow-hidden -z-20 opacity-35">
        <img
          src="/img/helloBlock/blob.svg"
          alt=""
          className="hidden md:block w-full relative -top-1/4"
        />
        <div
          className="md:hidden block h-screen relative bg-cover bg-center"
          style={{
            backgroundImage: "url('/img/helloBlock/blob.svg')",
          }}
        ></div>
      </div>

      <div className="w-full h-full absolute flex justify-start items-end overflow-y-hidden">
        <ParicleBG
          key="HelloBlock"
          name="HelloBlock"
          className="relative w-full h-full -z-20"
          images={HelloBlockParticleBg}
        />

        <div className="absolute w-11/12 flex justify-end items-end">
          <img
            src="/img/helloBlock/singer.png"
            alt=""
            className="h-2/3 animate__animated animate__fadeInUp"
          />
        </div>
      </div>

      <div className="relative z-20 flex items-center justify-center w-full animate__animated animate__fadeInUp md:mt-24">
        <div className="z-10 w-11/12 flex flex-col items-start justify-start gap-y-10">
          <p className="font-extralight mainText relative -left-2">KUSTOM</p>
          <div className="flex flex-col gap-y-5 text-lg md:text-4xl">
            <p className="font-semibold">
              Шумное проведение праздников со вкусом!
            </p>
            <p className="text-sm md:text-3xl md:font-extralight">
              *стильная и профессиональная группа
            </p>
          </div>

          <a
            rel="noopener noreferrer"
            href="https://wa.me/79891563506"
            target="_blank"
            className="cursor-pointer rounded-xl border border-white text-white px-10 py-2 md:px-14 md:py-4  mt-2 md:text-3xl hover:bg-white hover:text-black"
          >
            УЗНАТЬ СТОИМОСТЬ
          </a>
        </div>
      </div>
    </div>
  );
};
