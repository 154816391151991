import Particles, { initParticlesEngine } from "@tsparticles/react";
import type {
  ISourceOptions,
  RecursivePartial,
  SingleOrMultiple,
} from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useMemo, useState } from "react";
import { useIsMobile } from "../../contexts/isMobileContext";

export interface ParticleImage {
  src: string; // Путь к изображению по умолчанию
  width: number;
  height: number;
}

export interface ParicleBGProps {
  name: string;
  className?: string;
  images: RecursivePartial<SingleOrMultiple<ParticleImage>>;
}

export const ParicleBG = ({ name, className, images }: ParicleBGProps) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const isMobile = useIsMobile();

  const options: ISourceOptions = useMemo(
    () => ({
      fullScreen: {
        enable: false,
      },
      fpsLimit: 60,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
        },
        modes: {
          push: {
            quantity: 1,
          },
        },
      },
      particles: {
        number: {
          value: isMobile ? 20 : 7,
          density: {
            enable: true,
            value_area: isMobile ? 200 : 1000,
          },
        },
        shape: {
          type: "image",
          options: {
            image: images,
          },
        },
        reduceDuplicates: true,
        opacity: {
          value: {
            min: 0.85,
            max: 1,
          },
          random: true,
        },
        size: {
          value: {
            min: 80,
            max: 120,
          },
          random: true,
        },
        move: {
          enable: true,
          speed: 0.1,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "bounce",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      detectRetina: true,
    }),
    [images, isMobile]
  );

  return init ? (
    <Particles
      id={`tsparticles-${name}`}
      className={className}
      options={options}
    />
  ) : (
    <></>
  );
};
