import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export interface isMobileProviderProps {
  children: ReactNode;
}

const isMobileContext = createContext<boolean>(true);
export const useIsMobile = () => useContext(isMobileContext);

export const IsMobileProvider = ({ children }: isMobileProviderProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <isMobileContext.Provider value={isMobile}>
      {children}
    </isMobileContext.Provider>
  );
};
