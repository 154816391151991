import { ReactNode } from "react";

export interface SocialNetwork {
  icon: ReactNode;
  src: string;
  label: string;
}

const SocialNetworks = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 md:w-10 md:h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m4.5 12.75 6 6 9-13.5"
        />
      </svg>
    ),
    label: "VK",
    src: "https://vk.com/palazzo.singers",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 md:w-10 md:h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
        />
      </svg>
    ),
    label: "Instagram",
    src: "https://instagram.com/org.kustom?igshid=OGQ5ZDc2ODk2ZA==",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 md:w-10 md:h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    ),
    label: "Youtube",
    src: "https://www.youtube.com/@o.maximova1094",
  },
] as SocialNetwork[];

export const Footer = () => {
  return (
    <div
      className="my-5 md:my-16 w-11/12 flex flex-col mb-10 gap-y-3 md:gap-y-6"
      id="contacts"
    >
      <div className="w-full h-fit bg-black absolute opacity-75 hidden md:block -z-10"></div>
      <p className="text-3xl md:text-6xl mb-3">Наши соцсети:</p>

      {SocialNetworks.map((SN, index) => (
        <a
          className="flex flex-row gap-x-3 md:gap-x-6 md:text-3xl cursor-pointer"
          key={index}
          href={SN.src}
          target="_blank"
          rel="noopener noreferrer"
        >
          {SN.icon}
          <p>{SN.label}</p>
        </a>
      ))}
    </div>
  );
};
