import { BorderChars } from "../Chars/BorderChars/BorderChars";
import { useIsMobile } from "../../contexts/isMobileContext";

export const FillOutStyle = () => {
  const isMobile = useIsMobile();

  return (
    <div
      className="relative w-full flex flex-col items-center justify-start gap-y-5 md:gap-10"
      id="promo"
    >
      <div className="absolute w-full h-full bg-gradient-to-br from-black from-50% to-black to-80% via-mainPurple -z-10 opacity-35"></div>

      <p className="w-11/12 text-3xl md:text-6xl">ПОЧУВСТВУЙТЕ НАШ СТИЛЬ</p>
      <div className="w-11/12 flex flex-col md:flex-row gap-x-16 gap-y-5 items-start">
        <iframe
          width={isMobile ? 350 : 1080}
          height={isMobile ? 200 : 640}
          src="https://www.youtube.com/embed/ZGCXX0HDRFY?si=DXEAnm0Cnd0C49MJ"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>

        <BorderChars />
      </div>
    </div>
  );
};
