export const Reviews = () => {
  return (
    <div className="w-11/12 flex flex-col items-start justify-start gap-y-4 md:gap-y-10">
      <div className="w-full flex flex-row items-center gap-x-5 md:gap-x-20">
        <div className="w-1/3 ">
          <div className="w-[80px] h-[80px] md:w-full md:h-full rounded-full">
            <img
              src="/img/review.jpeg"
              className="w-full h-full rounded-full"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col items-center md:items-start justify-center md:justify-start text-sm md:text-xl gap-y-2 md:gap-y-6">
          <p>
            С нашим репертуаром и портфолио вы можете ознакомиться написав
            концертному директору
          </p>
          <p>
            Если вы хотите услышать определенную песню, но не можете найти ее в
            нашем репертуаре, не расстраивайтесь - мы подготовим ее специально
            для вас!
          </p>
        </div>
      </div>
      <div className="text-[14px] md:text-[18px] flex flex-col">
        <p className="">Олеся Максимова</p>
        <p className="">концертный директор</p>
        <p className="font-extralight mt-2">По всем вопросам</p>
      </div>
    </div>
  );
};
