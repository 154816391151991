import { ReactNode } from "react";
import "animate.css/animate.min.css";
import { Link } from "react-scroll";

export interface NavElementType {
  name: ReactNode;
  link: string | undefined;
  color: string | undefined;
  idToScroll: string | undefined;
}

const NavElements = [
  { name: "Промо", idToScroll: "promo" },
  { name: "|", color: "red" },
  { name: "Репертуар", idToScroll: "repertoire" },
  { name: "|", color: "red" },
  { name: "Состав", idToScroll: "command" },
  { name: "|", color: "red" },
  { name: "Контакты", idToScroll: "contacts" },
] as NavElementType[];

export const NavBar = () => {
  return (
    <nav className="w-full top-0 relative z-50 animate__animated animate__fadeInDown">
      <div className="relative z-10 md:text-4xl flex flex-row justify-between md:justify-around items-center py-3 px-1 md:px-3">
        <div className="hidden md:flex flex-row gap-x-4 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <p>Москва</p>
        </div>
        <div className="hidden md:flex">|</div>
        {NavElements.map((NE, index) =>
          NE.color === "red" ? (
            <div className="uppercase" key={index}>
              {NE.name}
            </div>
          ) : (
            <Link
              to={NE.idToScroll ?? ""}
              smooth={true}
              duration={1000}
              offset={-20}
              className="uppercase cursor-pointer md:py-10"
              key={index}
            >
              {NE.name}
            </Link>
          )
        )}
      </div>
    </nav>
  );
};
