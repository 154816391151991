import { useIsMobile } from "../../contexts/isMobileContext";

export interface CommandElementData {
  img: string;
  label: string;
  role: string;
  description: string[];
  mobileDescription: string[];
}

export interface CommandElementProps {
  data: CommandElementData;
  handlePrev: Function;
  handleNext: Function;
}

export const CommandElement = ({
  data,
  handlePrev,
  handleNext,
}: CommandElementProps) => {
  const isMobile = useIsMobile();

  return (
    <div className="relative w-full h-full flex flex-col items-center md:items-start md:flex-row gap-x-20 gap-y-5 mt-6">
      <div className="w-full md:w-1/2 flex flex-col gap-y-3 md:gap-y-10 md:ml-10">
        <div className="flex flex-row justify-between">
          <p className="text-4xl md:text-6xl font-bold">{data.label}</p>

          <div className="md:hidden flex items-center w-fit">
            <div
              onClick={() => handlePrev()}
              className="cursor-pointer relative z-20 w-fit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </div>
            <div
              onClick={() => handleNext()}
              className="cursor-pointer z-20 w-fit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <p className="text-3xl text-mainPurple line-clamp-2">{data.role}</p>
        <ul className="flex flex-col gap-y-2 md:gap-y-5 text-base md:text-xl list-disc list-inside">
          {isMobile
            ? data.mobileDescription.map((desc, index) => (
                <li key={index}>{desc}</li>
              ))
            : data.description.map((desc, index) => (
                <li key={index}>{desc}</li>
              ))}
        </ul>
      </div>
      <img src={data.img} alt="" className="w-11/12 md:w-1/3 rounded-2xl" />
    </div>
  );
};
