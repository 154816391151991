export interface BorderCharsElementType {
  label: string;
  text: string;
}

const borderCharsElements = [
  {
    label: "Плэйлист",
    text: "Работаем нон-стоп без пауз, соединяя в микс",
  },
  {
    label: "Профессиональные музыканты",
    text: "Профессионалы в своем деле",
  },
] as BorderCharsElementType[];

export const BorderChars = () => {
  return (
    <div className="flex flex-col gap-y-4 md:gap-y-12 items-start justify-center">
      {borderCharsElements.map((borderCh, index) => (
        <div
          className="border-l border-mainPurple pl-2 md:pl-6 flex flex-col gap-y-2"
          key={index}
        >
          <p className="text-3xl md:text-4xl text-mainPurple">
            {borderCh.label}
          </p>
          <p className="md:text-xl">{borderCh.text}</p>
        </div>
      ))}
    </div>
  );
};
