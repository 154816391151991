export interface GalleryElProps {
  image: string;
}

const imageFilenames = [
  "IMG_2660.JPEG",
  "IMG_2685.PNG",
  "IMG_2692.PNG",
  "IMG_2665.PNG",
  "IMG_2688.PNG",
  "IMG_2667.PNG",
  "IMG_2686.PNG",
  "IMG_2687.PNG",
  "IMG_2689.PNG",
  "IMG_2669.PNG",
  "IMG_2670.PNG",
  "IMG_2691.PNG",
  "IMG_2668.PNG",
  "IMG_2684.PNG",
  "IMG_2690.PNG",
  "IMG_2685.PNG",
];

const GalleryEl = ({ image }: GalleryElProps) => {
  return (
    <div className="w-[110px] h-[140px] md:w-[320px] md:h-[400px]">
      <img
        src={`/img/gallery/${image}`}
        loading="lazy"
        alt=""
        className="w-[110px] h-[140px] md:w-[320px] md:h-[400px]"
      />
    </div>
  );
};

export const GalleryCustomeElement = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <p className="uppercase text-xl md:text-5xl">kustom</p>
    </div>
  );
};

export const Gallery = () => {
  return (
    <div className="mt-5 md:mt-16 w-full flex items-center justify-center">
      <div className="w-full flex flex-col items-start justify-center gap-y-5 md:gap-y-12">
        <div className="w-11/12 self-center">
          <p className="text-3xl md:text-6xl">
            Восхищаем своими выступлениями и заряжаем на позитив!
          </p>
        </div>
        <div className="relative w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-rounded-full scrollbar-none snap-x bg-white text-black custom-scrollbar">
          <div className="snap-center h-full grid grid-rows-2 grid-flow-col gap-y-1 gap-x-1 items-start justify-start">
            <GalleryCustomeElement />
            {imageFilenames.map((image, index) => (
              <GalleryEl image={image} key={index} />
            ))}
            <GalleryCustomeElement />
          </div>
        </div>
      </div>
    </div>
  );
};
