const EventsList = [
  "Свадьба",
  "День рождения",
  "Городской праздник",
  "Корпоратив",
  "Новый год",
  "Выпускной",
  "Юбилей",
  "Клубное мероприятие",
  "Другое",
] as string[];

export const Events = () => {
  return (
    <div
      className="w-full h-fit flex flex-col items-center justify-center gap-y-5 md:gap-y-20 relative"
      id="repertoire"
    >
      <div className="absolute w-full h-full bg-gradient-to-b from-black via-40%  to-black via-mainPurple -z-10 opacity-20"></div>

      <p className="w-11/12 text-3xl md:text-6xl">
        МЫ ПРОВОДИМ ТАКИЕ МЕРОПРИЯТИЯ:
      </p>
      <div className="w-11/12 flex flex-col-reverse md:flex-row items-center justify-between gap-x-20">
        <ul className="w-full md:w-1/3 text-xl md:text-4xl grid grid-cols-2 md:flex flex-col gap-y-4 md:gap-y-6">
          {EventsList.map((E, index) => (
            <li
              className="border-l-2 px-5 border-l-mainPurple w-fit"
              key={index}
            >
              {E}
            </li>
          ))}
        </ul>

        <div className="w-2/3 hidden items-center justify-center md:flex">
          <div className="w-fit relative rounded-l-3xl">
            <img
              src="/img/gallery/IMG_2684_min.PNG"
              alt=""
              className="h-[800px] relative rounded-bl-[300px] rounded-tr-[300px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
