import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Parallax } from "swiper/modules";
import "swiper/css";

import { ParicleBG, ParticleImage } from "../ParticleBG/ParticleBG";
import { CommandElement, CommandElementData } from "./Element";
import { useCallback, useRef } from "react";
import { useIsMobile } from "../../contexts/isMobileContext";

const CommandData = [
  {
    label: "Илья Демин",
    role: "Вокалист",
    description: [
      "6 лет регулярно выступает как сольно, так и в составе хоров и кавер",
      "Работает во всех желаемых жанрах: эстрада, джаз, шансон, рок, народные песни, a'capella",
      "Развитый слух и хорошие навыки читки с листа",
      "Владение вокальными и артистическими навыками.",
      "Солист иммерсивной оперы «Кармен» в ресторане Турандот.",
      "Выступает на главных сценах Москвы: Кремлевский дворец, Crocus City Hall, концертный зал Зарядье, Московский международный Дом музыки, БЗК (большой зал консерватории), Зал Церковных Соборов Храма Христа Спасителя.",
      "Участник проектов: Таврида, Музыкавместе, Золотой Громофон.",
      "Лауреат всероссийских и международных конкурсов.",
    ],
    mobileDescription: [
      "6 лет регулярно выступает как сольно, так и в составе хоров и кавер",
      "Работает во всех желаемых жанрах: эстрада, джаз, шансон, рок, народные песни, a'capella",
      "Развитый слух и хорошие навыки читки с листа",
    ],
    img: "/img/gallery/IMG_2660.JPEG",
  },
  {
    label: "Павел Волобуев",
    role: "Саксофонист",
    description: [
      "Гран-при и лауреат первой степени международных конкурсов",
      "Серебряный призер Дельфийских игр",
      "Работает со звездами и артистами Российской Федерации, в том числе Ларисой Долиной, Игорем Бутманом и другими знаменитыми джазовыми музыкантами нашей страны",
    ],
    mobileDescription: [
      "Гран-при и лауреат первой степени международных конкурсов",
      "Серебряный призер Дельфийских игр",
      "Работает со звездами и артистами Российской Федерации, в том числе Ларисой Долиной, Игорем Бутманом и другими знаменитыми джазовыми музыкантами нашей страны",
    ],
    img: "/img/gallery/IMG_2669.PNG",
  },
  {
    label: "Софья Головина",
    role: "Вокалистка",
    description: [
      "Лауреат международных конкурсов: Китай, Испания, Словения",
      "Призер всероссийского конкурса",
      "Студентка года Государсвенного музыкально-педагогического института имени М. М. Ипполитова-Иванова 2023",
      "Гран-при и победитель многочисленных российских вокальных фестивалей и конкурсов",
      "Защищала честь и выступала на масштабном мероприятии в День города Химки (2 сентября 2018 года)",
    ],
    mobileDescription: [
      "Лауреат международных конкурсов: Китай, Испания, Словения",
      "Призер всероссийского конкурса",
    ],
    img: "/img/gallery/IMG_2665.PNG",
  },
  {
    label: "Дайана Даулетбаева",
    role: "Вокалистка",
    description: [
      "Лауреат и обладатель специальных призов всероссийских и международных конкурсов",
      "Широкий классический репертуар: исполнительница партии сопрано в Реквиеме Козловского в благотворительном концерте барочной музыки в ДШИ им. Мамонтова; исполнительница главной партии  в опере Дж.-К. Менотти «Телефон» (Люси) в Римско-Католическом костеле Непорочного зачатия",
    ],
    mobileDescription: [
      "Лауреат и обладатель специальных призов всероссийских и международных конкурсов",
      "Широкий классический репертуар: исполнительница партии сопрано в Реквиеме Козловского в благотворительном концерте барочной музыки в ДШИ им. Мамонтова; исполнительница главной партии  в опере Дж.-К. Менотти «Телефон» (Люси) в Римско-Католическом костеле Непорочного зачатия",
    ],
    img: "/img/gallery/IMG_2668.PNG",
  },
  {
    label: "Диана Замилова",
    role: "Вокалистка",
    description: [
      "Большой опыт выступления на различных площадках России",
      "Работает как в оперном, так и в эстрадном жанре, джазе. Исполняет народные песни, в том числе татарские.",
      "Владение вокальными и артистическими навыками, хороший слух и чувство ритма",
      "Выступает на главных сценах Москвы: Кремлевский дворец, Crocus City Hall, концертный зал Зарядье, Московский международный Дом музыки, Live ARENA.",
      "Участница проектов «Щелкунчик в Зарядье», «Щелкунчик. Сказка», «Музыкавместе», и др.",
      "Работает главным помощником режиссера на масштабных проектах: «Торжественный вечер к 100-летию Расула Гамзатова» в Махачкале, «Люди, люди – высокие звезды…» к 100-летию со дня рождения Расула Гамзатова в Государственном Кремлевском Дворце, «Большая сцена», «День знаний со звездами в Кремле», «Кармен», «Приношение Дягилеву» в Зарядье, «Моцарт ГАЛА» и др.",
      "Лауреат международных и всероссийских конкурсов вокалистов и дирижеров.",
    ],
    mobileDescription: [
      "Большой опыт выступления на различных площадках России",
      "Работает как в оперном, так и в эстрадном жанре, джазе. Исполняет народные песни, в том числе татарские.",
      "Владение вокальными и артистическими навыками, хороший слух и чувство ритма",
    ],
    img: "/img/gallery/IMG_2670.PNG",
  },
  {
    label: "Михаил Гомелев",
    role: "Барабанщик",
    description: [
      "Мультижанровый артист",
      "Мультиинструменталист: владеет навыком игры на гитаре (электро, бас, акустика, классика), укулеле, мандолине, гуслях, народных духовых инструментах, перкуссионных инструментах, ударной установке.",
      "Занимается созданием как аранжировок, так и композиций с нуля для различных проектов, мюзиклов, спектаклей, шоу.",
      'Является волонтёром благотворительного фонда "Дети Марии", где проводит мастер-классы и участвует в концертах',
      "Лауреат всероссийских и международных конкурсов",
    ],
    mobileDescription: [
      "Мультижанровый артист",
      "Мультиинструменталист: владеет навыком игры на гитаре (электро, бас, акустика, классика), укулеле, мандолине, гуслях, народных духовых инструментах, перкуссионных инструментах, ударной установке.",
      "Занимается созданием как аранжировок, так и композиций с нуля для различных проектов, мюзиклов, спектаклей, шоу.",
    ],
    img: "/img/gallery/IMG_2667.PNG",
  },
] as CommandElementData[];

const CommandParticleBg = [
  {
    src: "/img/blobs/1.svg",
    height: 300,
    width: 300,
  },
  {
    src: "/img/blobs/2.svg",
    height: 300,
    width: 300,
  },
] as ParticleImage[];

export const Command = () => {
  const sliderRef = useRef<SwiperRef>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const isMobile = useIsMobile();

  return (
    <div className="py-5 md:py-16 bg-white text-black relative w-full h-full flex flex-col items-center justify-center gap-y-5 md:gap-y-20 ">
      <p className="text-3xl md:text-6xl w-11/12">
        Кто будет зажигать на вашем мероприятии?
      </p>

      <div className="relative w-full h-full flex flex-row items-center justify-center gap-x-2">
        <div onClick={handlePrev} className="cursor-pointer hidden md:block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>

        {isMobile ? (
          <div className="w-full h-full">
            <Swiper
              ref={sliderRef}
              className="w-full h-full"
              spaceBetween={50}
              slidesPerView={1}
              parallax={true}
              modules={[Parallax]}
            >
              <div
                slot="container-start"
                className="absolute left-0 top-0 w-[130%] h-full"
                data-swiper-parallax="-23%"
              >
                <ParicleBG
                  key="CommandBlock"
                  name="CommandBlock"
                  className="w-full h-full"
                  images={CommandParticleBg}
                />
              </div>
              {CommandData.map((el, index) => (
                <SwiperSlide key={index} className="w-5/6">
                  <div className="w-full flex items-center justify-center">
                    <div className="w-5/6">
                      <CommandElement
                        data={el}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div className="w-11/12 h-full">
            <Swiper
              ref={sliderRef}
              className="w-full h-full"
              spaceBetween={50}
              slidesPerView={1}
              parallax={true}
              modules={[Parallax]}
            >
              <div
                slot="container-start"
                className="absolute left-0 top-0 w-[130%] h-full"
                data-swiper-parallax="-23%"
              >
                <ParicleBG
                  key="CommandBlock"
                  name="CommandBlock"
                  className="w-full h-full"
                  images={CommandParticleBg}
                />
              </div>
              {CommandData.map((el, index) => (
                <SwiperSlide key={index} className="w-5/6">
                  <CommandElement
                    data={el}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        <div onClick={handleNext} className="cursor-pointer hidden md:block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
