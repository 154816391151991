interface Char {
  num: string;
  label: string;
}

const CharsElements = [
  { num: "10+", label: "Меняем сценические образы под каждый репертуар" },
  { num: "2+", label: "Состава с разными музыкальными инструментами " },
  { num: "5+", label: "Профессиональных вокалистов " },
] as Char[];

export const Chars = () => {
  return (
    <div className="w-11/12 my-5 md:my-16 grid grid-cols-3 gap-x-2 md:gap-x-20 items-start justify-center">
      {CharsElements.map((char, index) => (
        <div
          className="flex flex-col md:flex-row items-start md:items-center justify-start gap-y-2 gap-x-5"
          key={index}
        >
          <p className="text-5xl md:text-8xl text-mainPurple">{char.num}</p>
          <p className="text-[12px] md:text-xl">{char.label}</p>
        </div>
      ))}
    </div>
  );
};
