import { ReactNode } from "react";
import { SocialNetwork } from "../Footer/Footer";

const russianHits = [
  "Елена Темникова",
  "Anna Asti",
  "2Маши",
  "Zivert",
  "Cream Soda&Хлеб",
  "Моя Мишель",
  "Мумий Троль&Даня Милохин",
  "Dead Blonde",
  "Anikv",
  "Баста",
  "Лолита",
  "Serebro и др.",
] as string[];

const foreignHits = [
  "Maneskin",
  "Рэй Чарльз",
  "Sia",
  "Адель",
  "Дэйв Стюарт",
  "Элис Мертон",
  "Louis Armstrong",
  "Michael Buble",
  "Abba",
  "Brithney Spears",
  "Леди Гага",
  "Imagine Dragons",
  "Nirvana и др.",
] as string[];

export interface RepertoireElementProps {
  children: ReactNode;
  classname?: string;
}

export const RepertoireElement = ({
  children,
  classname,
}: RepertoireElementProps) => (
  <div className={`z-10 flex flex-col gap-y-5 w-fit ${classname}`}>
    <svg height="30" width="30" xmlns="http://www.w3.org/2000/svg">
      <circle r="14" cx="15" cy="15" fill="black" />
    </svg>

    {children}
  </div>
);

const SocialNetworks = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 md:w-12 md:h-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
        />
      </svg>
    ),
    label: "Whatsapp",
    src: "https://wa.me/79891563506",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 md:w-12 md:h-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m4.5 12.75 6 6 9-13.5"
        />
      </svg>
    ),
    label: "VK",
    src: "https://vk.com/palazzo.singers",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 md:w-12 md:h-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
        />
      </svg>
    ),
    label: "Instagram",
    src: "https://instagram.com/org.kustom?igshid=OGQ5ZDc2ODk2ZA==",
  },
] as SocialNetwork[];

export const Repertoire = () => {
  return (
    <div className="relative bg-white text-black w-full my-5 md:my-16 flex items-center justify-center">
      <div className="absolute w-full h-full bg-gradient-to-tr from-white from-20% to-white to-80% via-mainPurple opacity-35"></div>

      <div className="relative w-11/12 flex flex-col items-start justify-start py-5 md:py-20 gap-y-5 md:gap-y-10">
        <p className="text-3xl md:text-6xl">РЕПЕРТУАР</p>

        <div className="relative w-full grid grid-cols-2 md:grid-cols-3">
          <div className="absolute w-full top-[15px]">
            <hr className="w-full bg-black border-1 border-gray-600" />
          </div>

          <RepertoireElement>
            <div className="flex flex-col gap-y-2 text-md md:text-2xl">
              <div className="font-bold md:mb-3 text-xl md:text-3xl h-12 flex items-end">
                <p>Русские хиты</p>
              </div>

              {russianHits.map((hit, index) => (
                <p key={index}>{hit}</p>
              ))}
            </div>
          </RepertoireElement>

          <RepertoireElement>
            <div className="flex flex-col gap-y-2 text-md md:text-2xl">
              <div className="font-bold md:mb-3 text-xl md:text-3xl h-12 flex items-end">
                <p>Зарубежные хиты</p>
              </div>
              {foreignHits.map((hit, index) => (
                <p key={index}>{hit}</p>
              ))}
            </div>
          </RepertoireElement>

          <RepertoireElement classname="hidden md:block">
            <div className="flex flex-col gap-y-4 text-2xl">
              <p className="font-bold mb-3 text-3xl">
                Для уточнения деталей пишите
              </p>

              {SocialNetworks.map((SN, index) => (
                <a
                  className="flex flex-row items-center gap-x-3 md:gap-x-6 md:text-3xl cursor-pointer"
                  key={index}
                  href={SN.src}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {SN.icon}
                  <p>{SN.label}</p>
                </a>
              ))}
            </div>
          </RepertoireElement>
        </div>

        <div className="md:hidden flex flex-col gap-y-4 text-2xl">
          <p className="font-bold text-xl">Для уточнения деталей пишите</p>

          {SocialNetworks.slice(0, 2).map((SN, index) => (
            <a
              className="flex flex-row items-center gap-x-3 md:gap-x-6 md:text-3xl cursor-pointer"
              key={index}
              href={SN.src}
              target="_blank"
              rel="noopener noreferrer"
            >
              {SN.icon}
              <p>{SN.label}</p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
